/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #D0D3D4 #FFFFFF;
  }
  
  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  
  *::-webkit-scrollbar-track {
    background: #FFFFFF;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: #D0D3D4;
    border-radius: 20px;
    border: 3px solid #FFFFFF;
  }