.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
  /* height: 300px; */
}

.editor-class {
  background-color: #ffffff;
  padding: 1rem;
  border: 1px solid #ccc;
  height: 150px;
}

.toolbar-class {
  border: 1px solid #ccc;
}

.ql-editor{
  min-height:200px;
}