html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* .css-fau73z-MuiPaper-root-MuiAppBar-root {
  z-index: 0 !important;
} */

.side-drawer {
  height: 98vh;
  background: white;
  position: fixed;
  /* top: 0px; */
  top: 75px;
  bottom: 60px !important;
  right: 0;
  width: 90% !important;
  z-index: 1200 !important;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}
 @media (max-width: 540px) {
   .side-drawer {
     width: 100%;
     overflow: scroll;
     height:"90%";
   }
 }

 @media (min-width: 541px) {
   .side-drawer {
     width: 90%;
     height: 90%;
   }
 }
.side-drawer.open {
  transform: translateX(0);
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top: 0;
  right: 0;
  /* bottom: 60px !important; */
}
