html {
    height: 100%;
  }
  body {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  .side-drawernew {
    height: 100%;
    background: white;
    position: fixed;
    top: 75px;
    bottom: 60px !important;
    right: 0;
    /* width: 50%; */
    z-index: 200 !important;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    /* overflow-y: scroll; */
  }
     @media (max-width: 540px) {
       .side-drawernew {
         width: 100%
       }
     }
  
     @media (min-width: 541px) {
       .side-drawernew {
         width: 50%
       }
     }
  .side-drawernew.open {
    transform: translateX(0);
  }
  
  .backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    top: 0;
    right: 0;
    /* bottom: 60px !important; */
  }
  