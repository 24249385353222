/* table zindex */
.css-m5ze2r-menu {
  z-index: 9999;
}
.css-bty0wn {
  z-index: 0;
}
/* table zindex */

.css-1e83rmc-menu {
  z-index: 9999;
}

.submit-btn-box {
  width: "225px";
  height: "45px";
  background-color: "#3B5092";
  padding: "13px 84px";
  border-radius: "5px";
}
.submit-btn-font {
  color: "#FFFFFF";
  font-size: "15px";
  font-weight: "medium";
  line-height: "19px";
}

.cancel-btn-box {
  width: "225px";
  height: "45px";
  background-color: "#FFFFFF";
  padding: "13px 84px";
  border-radius: "5px";
}
.cancel-btn-font {
  color: "#3B5092";
  font-size: "15px";
  font-weight: "medium";
  line-height: "19px";
}

.btn-edit {
  text-indent: -999px;
  overflow: hidden;
  width: 20px;
  height: 20px;
  /* padding: 0;
  margin: 0; */
  /* border: 1px solid transparent; */
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.wrapper {
  position: relative;
}

/* div {
  display: flex;
  
} */
.ant-input-affix-wrapper {
  width: 40% !important;
}
.css-1mt2tfm-MuiPaper-root-MuiAppBar-root {
  z-index: 10 !important;
}

.ring {
  width: 50px;
  height: 50px;
  border: 0px solid #000;
  border-radius: 50%;
  position: absolute;
}
.ring:nth-child(1) {
  border-bottom-width: 8px;
  border-color: rgb(209, 44, 44);
  animation: rotate1 2s linear infinite;
}

.css-yzewud {
  z-index: 0 !important;
}

/* apllication stage */
.default {
  width: 8px;
  height: 8px;
  background-color: #d9d9d9;
  border-radius: 50%;
}
.completed-class {
  width: 8px;
  height: 8px;
  background-color: #3b5092;
  border-radius: 50%;
}
.in_progress-class {
  width: 8px;
  height: 8px;
  background-color: #ffae5e;
  border-radius: 50%;
}
.rejected-class {
  width: 8px;
  height: 8px;
  background-color: #ff0000;
  border-radius: 50%;
}
.modal-footer {
  justify-content: center !important;
}
.css-lyphtv {
  width: 100% !important;
}
/* .css-9h77g9 {
  margin-left: 0px !important;
} */
.css-honmcm-MuiGrid-root > .MuiGrid-item {
  padding-top: 0px !important;
}
.css-1gsv261 {
  position: static !important;
}
.css-e1df1o {
  z-index: 1 !important ;
}

/* .css-9h77g9 {
  z-index: 2 !important;
} */

/* sidenav zindex */
/* .css-1893wbf-MuiPaper-root-MuiAppBar-root {
  z-index: 1100 !important;
} */

/* login btn class */
/*Export csv modal css*/
.HeaderCSV {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.88);
  margin-left: 7px;
}

.CloseBtn {
  margin-right: 7px;
  margin-top: -16px;
  padding: 0px;
  background-color: #ffffff;
  cursor: "pointer";
  border-radius: 4px;
  color: grey;
}

.CloseBtn:hover {
  background-color: #ECF0F1;
  cursor: "pointer";
  border-radius: 4px;
  margin-right: 7px;
  margin-top: -16px;
  padding: 0px;
  color: rgba(0, 0, 0, 0.88);
}

.UlCSV {
  margin: 0px;
  margin-left: -25px;
  margin-top: -15px;
  padding-bottom: 6px;
}

.ListCSV {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.88);
}

.CheckboxCSS {
  color: #0958d9;
}

.login-btn {
  width: 93px;
  height: 39px;
  border-radius: 5px;
  border: 0px solid #fff;
  background-color: #fe5c00;
  color: "#fff";
  cursor: "pointer";
}

.login-btn3 {
  width: 80px;
  padding: 0px;
  padding-bottom: 4px;
  margin: 4px;
  border-radius: 6px;
  background-color: #0958d9;
}

.login-btn3-Text {
  font-size: normal;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}

.login-btn4 {
  width: 80px;
  padding: 0px;
  padding-bottom: 4px;
  margin: 4px;
  border-radius: 6px;
  border: 1px solid grey;
  background-color: #FFFFFF;
}

.login-btn4:hover {
  width: 80px;
  padding: 2px;
  padding-bottom: 4px;
  margin: 4px;
  border-radius: 6px;
  border: 1px solid #0958d9;
  background-color: #FFFFFF;
  color: #0958d9;
}

.login-btn4-Text {
  font-size: normal;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
}

.login-btn4-Text:hover {
  color: #0958d9;
}

.btn-txt {
  color: #fff;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
}
/* login btn class */

/* modal export */
.modal-dialog,
.modal-lg,
.modal-dialog-centered {
  margin-top: "78px";
}
.modal-content {
  margin-top: 80px !important;
}

.MuiBox-root .css-1vlgqf0 {
  display: none !important;
}

/* candidate profiling star rating classes */
/* .gzXWsi {
  min-height: 5vh;
  /* font-size: 0px; 
} */
/* candidate profiling star rating classes */
