.excellent {
  width: 113px;
  height: 25px;
  background-color: "#B7F4BB";
}
.medium {
  width: 113px;
  height: 25px;
  background-color: "#EA7500";
}
.poor {
  width: 113px;
  height: 25px;
  background-color: "#FFD2D2";
}

/* .css-b62m3t-container {
  position: relative;
  box-sizing: border-box;
  background-color: #e2b2ff;
  width: 90px;

  margin-right: auto;
  margin-left: auto;
  margin-bottom: 42px;
  border-radius: 50px;
  height: 30px;
} */

.css-l4kyc0-Control {
  /* border: none !important; */
  /* height: ; */
}
.jobrole_dd {
  position: relative;
  box-sizing: border-box;
  background-color: #e2b2ff;
  width: 200px;
  /* padding: 5px 10px; */
  /* margin: 0 auto 42px auto; */
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 26px;
  border-radius: 5px;
  /* height: 30px; */
  border: none !important;
}
.jobrole_dd_list {
  position: relative;
  box-sizing: border-box;
  background-color: #e2b2ff;
  width: 200px;
  /* padding: 5px 10px; */
  /* margin: 0 auto 42px auto; */
  margin-right: auto;
  margin-left: auto;
  /* margin-bottom: 26px; */
  border-radius: 5px;
  /* height: 30px; */
  border: none !important;
}

.apply-modal {
  justify-content: center !important;
}

.grid-class {
  width: 265px !important;
}
