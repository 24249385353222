html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
}

/* .css-fau73z-MuiPaper-root-MuiAppBar-root {
  z-index: 0 !important;
} */

.side-drawer {
  height: 98vh;
  background: white;
  position: fixed;
  /* top: 0px; */
  top: 75px;
  bottom: 60px !important;
  right: 0;
  width: 90% !important;
  z-index: 1200 !important;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}

@media (max-width: 540px) {
  .side-drawer {
    width: 100%;
    overflow: scroll;
    height: "90%";
  }
}

@media (min-width: 541px) {
  .side-drawer {
    width: 90%;
    height: 90%;
  }
}

.side-drawer.open {
  transform: translateX(0);
}

.backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  top: 0;
  right: 0;
  /* bottom: 60px !important; */
}

#crumbs ul li {
  display: inline;
}

#crumbs ul #li a {
  display: block;
  float: left;
  height: 33px;
  background: grey;
  text-align: center;
  padding: 7px 10px 0 27px;
  position: relative;
  margin: 0 7px 0 0;
  font-size: 12px;
  text-decoration: none;
  color: #fff;
}

#crumbs ul #liA a {
  display: block;
  float: left;
  height: 33px;
  background: #ffa726;
  text-align: center;
  padding: 7px 10px 0 27px;
  position: relative;
  margin: 0 7px 0 0;
  font-size: 12px;
  text-decoration: none;
  color: #fff;
}

#crumbs ul #li a:after {
  content: "";
  border-top: 17px solid #fff;
  border-bottom: 17px solid #fff;
  border-left: 17px solid grey;
  position: absolute;
  right: -7px;
  top: 0;
}

#crumbs ul #liA a:after {
  content: "";
  border-top: 17px solid #fff;
  border-bottom: 17px solid #fff;
  border-left: 17px solid #ffa726;
  position: absolute;
  right: -7px;
  top: 0;
}

#crumbs ul #li a:before {
  content: "";
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 17px solid #fff;
  position: absolute;
  left: 0;
  top: 0;
}

#crumbs ul #liA a:before {
  content: "";
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  border-left: 17px solid #fff;
  position: absolute;
  left: 0;
  top: 0;
}